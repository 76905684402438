
import { defineComponent } from 'vue'
import VideoBox from '@/components/video-box'

export default defineComponent({
  components: {
    VideoBox
  },
  setup() {
    return {}
  }
})
