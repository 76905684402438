
import { defineComponent } from 'vue'

import CourseTop from './cpns/course-top.vue'
import CourseSpecific from './cpns/course-specific.vue'
import CourseAll from './cpns/course-all.vue'

export default defineComponent({
  components: {
    CourseTop,
    CourseSpecific,
    CourseAll
  },
  setup() {
    return {}
  }
})
