import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6b690daa")
const _hoisted_1 = { class: "container-1140" }
const _hoisted_2 = { class: "recommended-section" }
const _hoisted_3 = { class: "recommended-course" }
const _hoisted_4 = { class: "recommended-title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_card = _resolveComponent("course-card")!
  const _component_item_carousel = _resolveComponent("item-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$filters.hh_t('recommendedTitle')), 1),
        _createVNode(_component_item_carousel, {
          class: "recommend-carousel",
          items: _ctx.hotCourseList,
          "items-to-show": !_ctx.$isMobile ? 4 : 1,
          "items-to-scroll": !_ctx.$isMobile ? 4 : 1
        }, {
          default: _withCtx(({ item }) => [
            _createVNode(_component_course_card, {
              course: item,
              class: "recommend-slide-card"
            }, null, 8, ["course"])
          ]),
          _: 1
        }, 8, ["items", "items-to-show", "items-to-scroll"])
      ])
    ])
  ]))
}