
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import ItemCarousel from '@/components/item-carousel'
import CourseCard from './course-card.vue'
import ExternalCard from './external-card.vue'

export default defineComponent({
  components: {
    ItemCarousel,
    CourseCard
    // ExternalCard
  },
  setup() {
    const store = useStore()
    //发送网络数据
    store.dispatch('course/getHotCourseListAction')
    store.dispatch('course/getExternalCourseListAction')
    // 从vuex中获取数据
    const hotCourseList = computed(
      () => store.getters[`course/hotCourseListData`]
    )
    const externalCourseList = computed(
      () => store.getters[`course/externalCourseListData`]
    )
    return {
      hotCourseList,
      externalCourseList
    }
  }
})
