import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-1140" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_top = _resolveComponent("course-top")!
  const _component_course_specific = _resolveComponent("course-specific")!
  const _component_course_all = _resolveComponent("course-all")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_course_top),
    _createVNode(_component_course_specific),
    _createVNode(_component_course_all, { home: "" })
  ]))
}